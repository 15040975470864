<template>
  <v-app>
    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    id: '',
  }),
  async mounted() {
    let params = new URLSearchParams(document.location.search);
    this.id = params.get("id");
    try{
      const response = await axios.post('https://evannapp.com/copilot/api/emailclicked', {
        id: this.id,
      });
      console.log('Email Clicked:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  },
}
</script>

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { loadFonts } from './plugins/webfontloader'

loadFonts()
const DEFAULT_TITLE = "Evan Napp";

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

createApp(App)
  .use(vuetify)
  .use(router)
  .mount('#app')

<template>
  <v-container>
    <v-row class="justify-center">
      <v-col :cols="[isMobile ? 12 : 7]" style="background-color: #ad0000; margin: 0">
        <v-row class="justify-center" style="height: 100%;">
          <v-col :cols="[isMobile ? 12 : 6]" class="d-flex align-center">
            <v-row class="justify-center">
              <v-col cols="12" class="text-center">
                <p class="about-me-text-name">Evan Napp</p>
              </v-col>
              <v-col cols="12" class="text-center">
                <p class="about-me-text-job">About Me</p>
              </v-col>
              <v-col cols="12" class="text-center">
                <p class="about-me-text-blurb">Evan Napp is a trained pilot with scholarships from company, company, and company. He is looking forward to continuing his career in aviation.</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="[isMobile ? 12 : 5]" style="margin: 0; padding: 0;">
        <v-img :src="require('@/assets/EvanSeniorPicBest.jpg')"></v-img>
      </v-col>
    </v-row>
    <v-row class="justify-space-around py-5" style="background-color: #bfbcbc;">
      <v-col :cols="[isMobile ? 6 : 3]" class="text-center">
        <h1>Resume</h1>
        <v-row class="justify-space-around">
          <v-col :cols="[isMobile ? 12 : 4]" class="text-center">
            <v-dialog
              v-model="resumeDialog"
              transition="dialog-bottom-transition"
              fullscreen
            >
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                  text="View"
                  v-bind="activatorProps"
                ></v-btn>
              </template>

              <v-card>
                <v-toolbar>
                  <v-btn
                    icon="mdi-close"
                    @click="resumeDialog = false"
                  ></v-btn>

                  <v-toolbar-title>Resume</v-toolbar-title>

                  <v-spacer></v-spacer>

                </v-toolbar>

                <div v-if="resumePdfUrl" style="height: 100vh;width: 100%;">
                <object :data="resumepdfUrlWithParams" type="application/pdf" style="width: 100%; height: 100%;"></object>
              </div>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col :cols="[isMobile ? 12 : 4]" class="text-center">
            <v-btn @click="downloadResumePdf">Download</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="[isMobile ? 6 : 3]" class="text-center">
        <h1>Transcripts</h1>
        <v-row class="justify-space-around">
          <v-col :cols="[isMobile ? 12 : 4]" class="text-center">
            <v-dialog
              v-model="transcriptDialog"
              transition="dialog-bottom-transition"
              fullscreen
            >
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                  text="View"
                  v-bind="activatorProps"
                ></v-btn>
              </template>

              <v-card>
                <v-toolbar>
                  <v-btn
                    icon="mdi-close"
                    @click="transcriptDialog = false"
                  ></v-btn>

                  <v-toolbar-title>Transcripts</v-toolbar-title>

                  <v-spacer></v-spacer>

                </v-toolbar>

                <div v-if="TranscriptPdfUrl" style="height: 100vh;width: 100%;">
                <object :data="transcriptpdfUrlWithParams" type="application/pdf" style="width: 100%; height: 100%;"></object>
              </div>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col :cols="[isMobile ? 12 : 4]" class="text-center">
            <v-btn @click="downloadTranscriptPdf">Download</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="3" class="text-center">
        <h1>Logbook</h1>
        <v-row class="justify-space-around">
          <v-col cols="4" class="text-center">
            <v-btn>View</v-btn>
          </v-col>
          <v-col cols="4" class="text-center">
            <v-btn>Download</v-btn>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
    <v-row class="justify-center pt-5 mt-5">
      <v-col :cols="[isMobile ? 12 : 6]">
        <v-row class="justify-center" style="height: 100%;">
          <v-col :cols="[isMobile ? 12 : 6]" class="d-flex align-center">
            <v-row class="justify-center">
              <v-col cols="12" class="text-center">
                <p class="contact-me-text-name">Contact Me</p>
              </v-col>
              <v-col cols="12" class="text-center">
                <p class="contact-me-text-blurb">I would love to hear from you! Please feel free to message me with any further questions you may have as well as any information you would like to share about opportunities you have available!</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="[isMobile ? 12 : 6]">
        <v-form @submit.prevent="submitContactForm">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="firstName" label="First Name" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="lastName" label="Last Name" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="email" label="Email" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="message" label="Message" required></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-btn type="submit">Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            TranscriptPdfUrl: null,
            resumePdfUrl: null,
            transcriptDialog: false,
            resumeDialog: false,
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            emailId: '',
        };
    },
    created() {
        this.fetchTranscripts();
        this.fetchResumes();
        this.emailId = this.$route.params.id;
    },
    computed: {
        transcriptpdfUrlWithParams() {
            const additionalParams = '#thumbnails=0&view=FitBV&toolbar=0';
            return this.TranscriptPdfUrl ? `${this.TranscriptPdfUrl}${additionalParams}` : null;
        },
        resumepdfUrlWithParams() {
            const additionalParams = '#thumbnails=0&view=FitBV&toolbar=0';
            return this.resumePdfUrl ? `${this.resumePdfUrl}${additionalParams}` : null;
        },
        isMobile() {
          console.log(this.$vuetify.display.smAndDown);
          return this.$vuetify.display.smAndDown;
        },
    },
    methods: {
        decodeBase64ToPdf(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], {
                type: 'application/pdf'
            });
            this.TranscriptPdfUrl = URL.createObjectURL(blob);
        },
        async fetchTranscripts() {
            const response = await axios.get('https://evannapp.com/copilot/api/transcripts');
            this.transcripts = response.data;
            if (this.transcripts.length > 0) {
                this.decodeBase64ToPdf(this.transcripts[0].base64PDF);
            }
        },
        resumeDecodeBase64ToPdf(base64) {
            const binaryString = window.atob(base64);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], {
                type: 'application/pdf'
            });
            this.resumePdfUrl = URL.createObjectURL(blob);
        },
        async fetchResumes() {
            const response = await axios.get('https://evannapp.com/copilot/api/resumes');
            this.resumes = response.data;
            this.resumeDecodeBase64ToPdf(this.resumes[0].base64PDF);
        },
        downloadResumePdf() {
            const url = this.resumepdfUrlWithParams;
            if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.download = 'EvanNappResume.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Resume PDF URL is not available.');
            }
        },
        downloadTranscriptPdf() {
            const url = this.transcriptpdfUrlWithParams;
            if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.download = 'EvanNappTranscripts.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('Resume PDF URL is not available.');
            }
        },
        async submitContactForm() {
          try {
            const response = await axios.post('https://evannapp.com/copilot/api/contact', {
              email: this.email,
              message: this.message
            });
            console.log('Contact form submitted:', response.data);
          } catch (error) {
            console.error('Error submitting contact form:', error);
          }
        },
    },
};
</script>


<style lang="css" scoped>
  .about-me-text-name {
    font-weight: 700 !important;
    color: white;
    font-size: 5rem;
  }
  .about-me-text-job {
    font-weight: 700 !important;
    color: white;
    font-size: 1.75rem;
  }
  .about-me-text-blurb {
    font-weight: 400 !important;
    color: white;
    font-size: 1rem;
  }
  .contact-me-text-name {
    font-weight: 700 !important;
    color: black;
    font-size: 5rem;
  }
  .contact-me-text-job {
    font-weight: 700 !important;
    color: black;
    font-size: 1.75rem;
  }
  .contact-me-text-blurb {
    font-weight: 400 !important;
    color: black;
    font-size: 1rem;
  }
</style>
import { createWebHistory , createRouter } from 'vue-router'

import HomeView from './App.vue'

const routes = [
  {
    path: '/',
    component: HomeView,
    meta: {
      title: 'Evan Napp'
    }
 },

]

const router = createRouter({
  history: createWebHistory (),
  routes,
})

export default router